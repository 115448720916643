<template>
    <div>
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :color="'#aa945b'"
        :is-full-page="fullPage">
        </loading>
        <CCard>
            <CCardHeader><b>Activity Logs</b></CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol sm="12">
                        <CPagination
                            @update:activePage="linkGen"
                            :activePage.sync="activePage"
							:pages="pageLength"
                            align="end"
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12">
                        <CDataTable 
                            :items="activity.logs"
                            :fields="fields"
                            :noItemsView='{ noResults: "No Activity Found", noItems: "No Activity Found" }'
                            :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
                            hover
                            sorter>
                            <template #email="{item}">
                                <td>
                                    <span style="color:green"><b>{{item.user.name}}</b></span>
                                    {{item.user.email}}
                                </td>
                            </template>
                            <template #created_at="{item}">
                                <td>{{getHumanDate(item.created_at)}}</td>
                            </template>
                            <template #params="{item, index}">
                                <td>
                                    <tree-view
                                        :data="JSON.parse(item.params)"
                                        :options="{rootObjectKey: 'params',maxDepth: 0, modifiable: false}"
                                    ></tree-view>
                                </td>
                            </template>
                            <template #request="{item, index}">
                                <td>
                                    <tree-view
                                        :data="JSON.parse(item.request)"
                                        :options="{rootObjectKey: 'params',maxDepth: 0, modifiable: false}"
                                    ></tree-view>
                                </td>
                            </template>

                        </CDataTable>
                    </CCol>
                </CRow>
				<CRow>
                    <CCol sm="12">
                        <CPagination
                            @update:activePage="linkGen"
                            :activePage.sync="activePage"
							:pages="pageLength"
                            align="end"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>
<script>
import { mapState } from "vuex";

import {TreeView} from "vue-json-tree-view"
import moment from 'moment';


export default {
    name:"Logs",
    components:{
        TreeView
    },
    data(){
        return{
            isLoading: false,
            fullPage: true,
            activePage:1,
			perPage:30,
            fields:[
                {key: 'email', label:'User'},
                {key: 'action', label:'Action'},
                {key: 'created_at', label:'Created At'},
                {key: 'params', label:'Params'},
                {key: 'request', label:'Requested'}

            ]
        }
    },
    computed: {
		...mapState({
		  total: state => state.activity.total
		}),
        ...mapState(["activity"]),
		pageLength(){
			let length = 0;
//			console.log(this.total);
			if(this.total <= this.perPage) {
				length = 1
			} else {
				let num = this.total / this.perPage
				length = Math.ceil(num)     
			}
			return length;
		}
    },
    async mounted() {
        this.isLoading = true;
        await this.$store.dispatch("auth/fetchUser");
        await this.fetch()
        this.isLoading = false;
  },
    watch:{
        $route: {
            immediate: true,
            async handler (route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page)
                    await this.fetch();
                }
            }
        },
    },
  methods:{
    getHumanDate : function (date) {
        return moment(date).format("D/M/YYYY LT");
    }, 
    linkGen(pageNum) {
        //alert(pageNum)
        if (this.$route.path === "/logs") {
            this.$router.push({ query: { page: pageNum }})
        } 
        else 
        {
          this.$router.push({ query: { page: pageNum }})
        }
      },

    async fetch() {
        this.isLoading = true;
        //var stringFilter = encodeURIComponent(JSON.stringify(this.getFilters()));
        await this.$store.dispatch("activity/fetchLogs", {
            query: this.$route.query
        });

        this.isLoading = false;
    },
  }

}
</script>