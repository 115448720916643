var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"color":'#aa945b',"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CCard',[_c('CCardHeader',[_c('b',[_vm._v("Activity Logs")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pageLength,"align":"end"},on:{"update:activePage":[_vm.linkGen,function($event){_vm.activePage=$event}],"update:active-page":function($event){_vm.activePage=$event}}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"items":_vm.activity.logs,"fields":_vm.fields,"noItemsView":{ noResults: "No Activity Found", noItems: "No Activity Found" },"tableFilter":{ label : "Filter", placeholder:"Type to Search..." },"hover":"","sorter":""},scopedSlots:_vm._u([{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticStyle:{"color":"green"}},[_c('b',[_vm._v(_vm._s(item.user.name))])]),_vm._v(" "+_vm._s(item.user.email)+" ")])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getHumanDate(item.created_at)))])]}},{key:"params",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('tree-view',{attrs:{"data":JSON.parse(item.params),"options":{rootObjectKey: 'params',maxDepth: 0, modifiable: false}}})],1)]}},{key:"request",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('tree-view',{attrs:{"data":JSON.parse(item.request),"options":{rootObjectKey: 'params',maxDepth: 0, modifiable: false}}})],1)]}}])})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pageLength,"align":"end"},on:{"update:activePage":[_vm.linkGen,function($event){_vm.activePage=$event}],"update:active-page":function($event){_vm.activePage=$event}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }